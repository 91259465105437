(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-ecommerce/assets/javascripts/ecommerce.js') >= 0) return;  svs.modules.push('/components/components/analytics-ecommerce/assets/javascripts/ecommerce.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};
  svs.components.analytics = svs.components.analytics || {};
  svs.components.analytics.constants = svs.components.analytics.constants || {};

  var ECOMMERCE_TARGET_LAYER = {
    ADOBE_LAYER: 'adobe',
    GOOGLE_LAYER: 'google',
    ALL_LAYERS: 'all'
  };

  svs.components.analytics.constants.ECOMMERCE_TARGET_LAYER = ECOMMERCE_TARGET_LAYER;

  svs.components.analytics.trackEcommerceEvent = function(event, targetLayer) {
    targetLayer = targetLayer || ECOMMERCE_TARGET_LAYER.GOOGLE_LAYER;

    if (!(event instanceof svs.components.analytics.EcommerceEvent)) {
      throw new Error('Type mismatch. Expected svs.components.analytics.EcommerceEvent.');
    }

    if ([ECOMMERCE_TARGET_LAYER.GOOGLE_LAYER, ECOMMERCE_TARGET_LAYER.ALL_LAYERS].includes(targetLayer)) {
      dataLayerPush(event);
    }
  };

  svs.components.analytics.EcommerceEvent = function(
    eventLabel,
    eventItems,
    eventType,
    addTotalValue,
    retailerAndUnitIds
  ) {
    this.event = eventLabel;
    if (eventType) {
      this.event_info = {
        type: eventType
      };
    }

    if (addTotalValue) {
      const value = eventItems.reduce((acc, curr) => {
        acc += curr.price;
        return acc;
      }, 0);

      this.ecommerce = {
        value,
        items: eventItems
      };

      const { retailId, unitId } = retailerAndUnitIds;
      if ((retailId || retailId === 0) && (unitId || unitId === 0)) {
        this.ecommerce = {
          value,
          items: eventItems,
          retailId: retailId,
          unitId: unitId
        };
      }
    } else {
      this.ecommerce = {
        items: eventItems
      };
    }
  };

  svs.components.analytics.EcommerceEventItem = function(
    productId,
    productTitle,
    price,
    tenant,
    variant,
    quantity
  ) {
    this.item_name = productTitle;
    this.item_id = productId;
    this.price = price;
    this.item_brand = tenant === 'lb' ? 'Sport & Casino' : 'Tur';
    this.item_variant = variant; 
    this.quantity = quantity;
  };

  svs.components.analytics.EcommerceEventItemObject = function({
    productId,
    productTitle,
    price,
    tenant,
    quantity,
    variant
  }) {
    return new svs.components.analytics.EcommerceEventItem(
      productId,
      productTitle,
      price,
      tenant,
      variant,
      quantity
    );
  };
})(svs);


 })(window);