(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-ecommerce/assets/javascripts/transaction.js') >= 0) return;  svs.modules.push('/components/components/analytics-ecommerce/assets/javascripts/transaction.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};
  svs.components.analytics = svs.components.analytics || {};
  svs.core = svs.core || {};
  svs.core.analytics = svs.core.analytics || {};
  svs.core.analytics.adl = svs.core.analytics.adl || {};
  svs.components.analytics.affiliation = svs.core.data.capabilities.formFactor;

  svs.components.analytics.trackTransaction = function(trans, options) {
    if (trans instanceof svs.components.analytics.transaction) {
      var dataLayerData = {
        event: 'transaction',
        eventInfo: {
          eventAction: 'Paid',
          eventName: trans.productName,
          effect: trans.productName,
          type: 'Wager'
        },
        transaction: {
          transactionID: trans.orderId,
          total: {
            shipping: trans.shippingAmount,
            priceWithTax: trans.totalAmount - trans.shippingAmount,
            transactionTotal: trans.totalAmount
          },
          item: []
        }
      };

      var adobeTrans = {
        orderId: trans.orderId,
        totalAmount: trans.totalAmount,
        items: []
      };

      if (options) {
        if (options.gameType) adobeTrans.gameType = options.gameType;
        if (options.gameAccount) adobeTrans.gameAccount = options.gameAccount;
      }

      var transmsg = new svs.core.log.Message(svs.core.log.MessageType.trans);
      transmsg.transId = trans.orderId;
      transmsg.transTotalAmount = trans.totalAmount;
      transmsg.transShipping = trans.shippingAmount;
      transmsg.transTax = trans.taxAmount;
      svs.core.log.track('svs.components.analytics', transmsg);

      trans.items.forEach(function(item) {
        var adobeItem = {
          productName: item.productName,
          SKU: item.SKU,
          price: item.price,
          quantity: item.quantity,
          details: {}
        };
        var sendItem = {
          productInfo: {
            productID: item.SKU,
            productName: item.productName,
            sku: item.SKU
          },
          quantity: item.quantity,
          price: {
            priceWithTax: item.price
          }
        };
        var lunaItem = '';
        for (var prop in item.category) {
          if (Object.prototype.hasOwnProperty.call(item.category, prop) && !sendItem.productInfo[prop]) {
            adobeItem.details[prop] = item.category[prop];
            sendItem.productInfo[prop] = item.category[prop];
            lunaItem += prop + '=' + item.category[prop] + ',';
          }
        }
        lunaItem = lunaItem.replace(/,+$/, '');

        dataLayerData.transaction.item.push(sendItem);
        adobeTrans.items.push(adobeItem);

        var msg = new svs.core.log.Message(svs.core.log.MessageType.item);
        msg.transId = trans.orderId;
        msg.itemCode = item.SKU;
        msg.itemName = item.productName;
        msg.itemVariation = lunaItem;
        msg.itemPrice = item.price;
        msg.itemQuantity = item.quantity;
        svs.core.log.track('svs.components.analytics', msg);
      });

      svs.core.analytics.adl.transaction = adobeTrans;
      window.adobeDataLayer.adlPush('trans', {
        site: svs.core.analytics.adl.site,
        consent: svs.core.analytics.adl.consent,
        user: svs.core.analytics.adl.user,
        page: svs.core.analytics.adl.page,
        transaction: svs.core.analytics.adl.transaction
      });
      dataLayerPush(dataLayerData);
    } else {
      throw new Error('Type mismatch. Expected svs.components.analytics.transaction.');
    }
  };

  svs.components.analytics.transaction = function(orderId, totalAmount, items, optTaxAmount, optShippingAmount, optAffiliation, optProductName) {
    this.orderId = orderId;
    this.totalAmount = !isNaN(totalAmount) ? parseFloat(totalAmount).toFixed(2) : 0;
    this.taxAmount = !isNaN(optTaxAmount) ? parseFloat(optTaxAmount).toFixed(2) : 0;
    this.shippingAmount = !isNaN(optShippingAmount) ? parseFloat(optShippingAmount).toFixed(2) : 0;
    this.affiliation = optAffiliation !== undefined ? optAffiliation : '.se';
    this.items = items;
    this.productName = optProductName;
  };

  svs.components.analytics.transactionItem = function(SKU, productName, price, quantity, optCategory) {
    this.SKU = SKU !== undefined ? SKU : '-';
    this.productName = productName !== undefined ? productName : '-';
    this.price = !isNaN(price) ? parseFloat(price).toFixed(2) : 0;
    this.quantity = !isNaN(quantity) ? parseInt(quantity, 10) : 1;
    this.category = optCategory;
  };
})(svs);


 })(window);