(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-ecommerce/assets/javascripts/track-ecommerce-event.js') >= 0) return;  svs.modules.push('/components/components/analytics-ecommerce/assets/javascripts/track-ecommerce-event.js');

svs.components = svs.components || {};
svs.components.analytics = svs.components.analytics || {};

const { resolveITSProductId, resolveProductId } = svs.utils.products;

const TENANT_QUICKPICK_NAMES = {
  lb: 'pix',
  tb: 'huxflux'
};

svs.components.analytics.resolveAndAddEcommerceEvent = function({
  eventLabel,
  items,
  retailId,
  unitId
}) {
  const eventItems = items.map(({
    productProperties
  }) => {
    const productId = resolveITSProductId(resolveProductId(productProperties.productFlag));
    const variant = productProperties.isQuickPick && TENANT_QUICKPICK_NAMES[productProperties.tenant] || 'manuell';

    return svs.components.analytics.EcommerceEventItemObject({
      productId,
      productTitle: productProperties.productTitle,
      price: productProperties.cost,
      tenant: productProperties.tenant,
      quantity: 1,
      variant
    });
  });

  const retailerAndUnitIds = { retailId, unitId };
  const ecommerceEvent = new svs.components.analytics.EcommerceEvent(
    eventLabel,
    eventItems,
    undefined,
    true,
    retailerAndUnitIds
  );

  svs.components.analytics.trackEcommerceEvent(ecommerceEvent);
};


 })(window);